//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import search from "@/mixins/search";
import isMobile from "~/mixins/isMobile.js";

export default {
  mixins: [search, isMobile],
  props: {
    title: {
      required: true,
    },
  },
  computed: {
    docs1() {
      let docs = [
        {
          type: "doc",
          title: "Tax ID",
          category: "Business Service",
          icon: 5,
        },
        {
          type: "doc",
          title: "Property Purchase Agreement",
          category: "Real Estate",
          icon: 4,
        },
        {
          type: "doc",
          title: "C-Corp Formation",
          category: "Business Formation",
          icon: 4,
        },
        {
          type: "photo",
          photo: "hero-re",
        },
        {
          type: "doc",
          title: "Power of Attorney",
          category: "Personal Affairs",
          icon: 2,
        },
        {
          type: "doc",
          title: "Articles of Organization",
          category: "Business Formation",
          icon: 4,
        },
        {
          type: "doc",
          title: "Notice to Quit",
          category: "Real Estate",
          icon: 4,
        },
        {
          type: "photo",
          photo: "hero-p",
        },
        {
          type: "doc",
          title: "LLC Formation",
          category: "Business Formation",
          icon: 3,
        },
        {
          type: "doc",
          title: "Living Will",
          category: "Estate Planning",
          icon: 2,
        },
        {
          type: "doc",
          title: "Corporate Bylaws",
          category: "Business",
          icon: 6,
        },
        {
          type: "photo",
          photo: "hero-biz",
        },
      ];

      return docs;
    },
    docs2() {
      let docs = [
        {
          type: "doc",
          title: "Employee Handbook",
          category: "Business",
          icon: 2,
        },
        {
          type: "photo",
          photo: "hero-biz2",
        },
        {
          type: "doc",
          title: "Articles of Incorporation",
          category: "Business Formation",
          icon: 3,
        },
        {
          type: "doc",
          title: "Lease Agreement",
          category: "Real Estate",
          icon: 1,
        },
        {
          type: "doc",
          title: "General Affidavit",
          category: "Personal Affairs",
          icon: 2,
        },
        {
          type: "doc",
          title: "Property Deed",
          category: "Real Estate",
          icon: 4,
        },
        {
          type: "photo",
          photo: "hero-p2",
        },
        {
          type: "doc",
          title: "Pour Over Will",
          category: "Estate Planning",
          icon: 2,
        },
        {
          type: "doc",
          title: "Lease Agreement",
          category: "Real Estate",
          icon: 4,
        },
        {
          type: "doc",
          title: "Non-Disclosure Agreement",
          category: "Human Resources",
          icon: 6,
        },
        {
          type: "photo",
          photo: "hero-biz3",
        },
        {
          type: "doc",
          title: "Last Will and Testament",
          category: "Estate Planning",
          icon: 2,
        },
      ];
      return docs;
    },
    docs3() {
      let docs = [
        {
          type: "doc",
          title: "Notice to Quit",
          category: "Real Estate",
          icon: 4,
        },
        {
          type: "doc",
          title: "Prenuptial Agreement",
          category: "Marriage",
          icon: 2,
        },
        {
          type: "doc",
          title: "Lease Agreement",
          category: "Real Estate",
          icon: 4,
        },
        {
          type: "photo",
          photo: "hero-biz",
        },
        {
          type: "doc",
          title: "Banking Resolution",
          category: "Business",
          icon: 5,
        },
        {
          type: "doc",
          title: "Residential Rental Agreement",
          category: "Real Estate",
          icon: 4,
        },

        {
          type: "photo",
          photo: "hero-re2",
        },
        {
          type: "doc",
          title: "Power of Attorney",
          category: "Personal Affairs",
          icon: 2,
        },
        {
          type: "doc",
          title: "Living Will",
          category: "Estate Planning",
          icon: 2,
        },
        {
          type: "doc",
          title: "LLC Operating Agreement",
          category: "Business Formation",
          icon: 3,
        },
        {
          type: "photo",
          photo: "hero-ep",
        },
      ];
      return docs;
    },
  },
  methods: {
    handleShowSearch() {
      if (this.isMobile) this.$nuxt.$emit("activateMobileSearch");
      else this.$nuxt.$emit("activateSearch");
    },
  },
};
