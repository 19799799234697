//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head() {
    return {
      script: [
        {
          type: "text/javascript",
          src:
            "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
        },
      ],
    };
  },
  mounted() {
    if (window.Trustpilot)
      this.$nextTick(() =>
        window.Trustpilot.loadFromElement(this.$refs.trustBoxRefStars)
      );
  },
};
